import { Form, Input, Modal } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import sampleImage from "../../assets/images/billboard vector.png";
import led from "../../assets/images/led.png";

const SignupModal = (props) => {
  return (
    <Modal
      title="Log in or Sign up"
      open={props.visible}
      footer={null}
      onCancel={props.handleClose}
      width={600}
    >
      <div className="grid grid-cols-2 gap-5">
        {props.modal === "login" ? (
          <div className="bg-pri-500 px-5 py-10 flex flex-col justify-center">
            <div className="text-white text-xl font-semibold text-center mb-10">
              We help small and medium sized businesses grow!
            </div>
            <img className="h-40 mx-auto" src={led} alt="sample" />
          </div>
        ) : (
          <div>
            <div className="text-center text-xl font-semibold mb-3">
              Sign up
            </div>
            <Form name="basic" layout="vertical" requiredMark={false}>
              <Form.Item
                // label="*Name"

                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your name!",
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
              <Form.Item
                // label="*Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email!",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item
                // label="*Email"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password!",
                  },
                ]}
              >
                <Input placeholder="Password" type="password" />
              </Form.Item>
              <Form.Item
                // label="*Email"
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: "Confirm your password!",
                  },
                ]}
              >
                <Input placeholder="Confirm password" type="password" />
              </Form.Item>

              <Link className="w-full text-white hover:text-white text-center bg-pri-500 hover:bg-sec-500 font-semibold py-3 px-5 rounded-xl inline-block">
                Sign up
              </Link>
            </Form>
            <div className="text-center mt-3 mb-2">or sign up with </div>

            <div className="flex items-center justify-center gap-5 mb-2">
              <div
                className="h-9 w-9 rounded-full flex items-center justify-center cursor-pointer hover:bg-pri-500 hover:text-white"
                style={{ border: "1px solid #d6d6d6" }}
              >
                <i className="ri-facebook-line text-base"></i>
              </div>
              <div
                className="h-9 w-9 rounded-full flex items-center justify-center cursor-pointer  hover:bg-pri-500 hover:text-white"
                style={{ border: "1px solid #d6d6d6" }}
              >
                <i className="ri-google-line text-base"></i>
              </div>
              <div
                className="h-9 w-9 rounded-full flex items-center justify-center cursor-pointer  hover:bg-pri-500 hover:text-white"
                style={{ border: "1px solid #d6d6d6" }}
              >
                <i className="ri-apple-line text-base"></i>
              </div>
            </div>
            <div className="text-center">
              Already have an account?{" "}
              <span
                className="text-pri-500 cursor-pointer"
                onClick={props.changeModalLogin}
              >
                Log in
              </span>
            </div>
          </div>
        )}
        {props.modal === "login" ? (
          <div className="my-auto">
            <div className="text-center text-xl font-semibold mb-3">Login</div>
            <Form name="basic" layout="vertical" requiredMark={false}>
              <Form.Item
                // label="*Name"

                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email!",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item
                // label="*Name"

                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password!",
                  },
                ]}
              >
                <Input type="password" placeholder="Password" />
              </Form.Item>
              <div className="text-pri-500 text-right mb-2">
                Forgot password?
              </div>
              <Link className="w-full text-white hover:text-white text-center bg-pri-500 hover:bg-sec-500 font-semibold py-3 px-5 rounded-xl inline-block">
                Log in
              </Link>
            </Form>
            <div className="text-center mt-2">
              Don't you have an account?{" "}
              <span
                className="text-pri-500 cursor-pointer"
                onClick={props.changeModalSignup}
              >
                Sign up
              </span>
            </div>
          </div>
        ) : (
          <div className="bg-pri-500 px-5 py-10 flex flex-col justify-center">
            <div className="text-white text-xl font-semibold text-center mb-10">
              Reach new customers in a big way!
            </div>
            <img className="h-40 mx-auto" src={sampleImage} alt="sample" />
          </div>
        )}
      </div>
    </Modal>
  );
};
export default SignupModal;
