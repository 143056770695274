import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/layout/Footer";
import SingleMainNavBar from "../components/layout/SingleMainNavBar";

function SingleItemLayout() {
  return (
    <>
      <SingleMainNavBar />
      <div className="pt-20">
        <Outlet />
      </div>
      <Footer />
    </>
  );
}

export default SingleItemLayout;
