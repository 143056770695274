import React, { useState } from "react";
import ItemCard from "../../components/app/ItemCard";
import FilterModal from "../../components/modals/FilterModal";

function SearchResult() {
  const [openFilter, setOpenFilter] = useState(false);
  return (
    <>
      <div className="container ">
        <div className="grid grid-cols-2 relative mt-5">
          <div>
            <div className="flex items-center justify-between pr-5 mb-5">
              <div>
                <div className="text-base font-semibold mb-1">121 Items</div>
                <div className="flex items-center text-xs gap-5 text-sec-400">
                  <div className="flex items-center gap-1">
                    <i className="ri-building-4-line"></i>
                    <div>TV Screen</div>
                  </div>
                  <div className="flex items-center gap-1">
                    <i className="ri-map-pin-2-line"></i>
                    <div>Toronto</div>
                  </div>
                  <div className="flex items-center gap-1">
                    <i class="ri-money-dollar-circle-line"></i>
                    <div>$20 - $50</div>
                  </div>
                </div>
              </div>
              <div
                onClick={() => setOpenFilter(true)}
                className="px-5 py-2.5 rounded-xl flex items-center gap-2 cursor-pointer  "
                style={{ border: "1px solid #d6d6d6" }}
              >
                <i className="ri-equalizer-fill"></i>
                Filter
              </div>
            </div>
            <div className="grid grid-cols-2 gap-5  pr-5 ">
              <ItemCard />
              <ItemCard />
              <ItemCard />
              <ItemCard />
              <ItemCard />
              <ItemCard />
              <ItemCard />
            </div>
          </div>
          <div
            className="sticky h-fit rounded-xl overflow-hidden mb-5"
            style={{ top: "100px" }}
          >
            <iframe
              style={{ height: "calc(100vh - 120px)" }}
              className="w-full"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=royal%20ontario%20musium&t=&z=13&ie=UTF8&iwloc=&output=embed"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            ></iframe>
          </div>
        </div>
      </div>
      <FilterModal
        visible={openFilter}
        handleClose={() => setOpenFilter(false)}
      />
    </>
  );
}

export default SearchResult;
