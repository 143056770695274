import React from "react";
import Banner from "../../components/web/Banner";
import Hero from "../../components/web/Hero";
import HowItWorks from "../../components/web/HowItWorks";
import WhatWeDo from "../../components/web/WhatWeDo";

function Home() {
  return (
    <>
      <Hero />
      <WhatWeDo />
      <HowItWorks />
      <Banner />
    </>
  );
}

export default Home;
