import React from "react";
import { Link } from "react-router-dom";

function Banner() {
  return (
    <div className="py-12   px-3 bg-pri-500">
      <div className="container">
        <div className="flex items-center justify-between">
          <div className="text-2xl font-semibold text-white">
            Create a free account and start growing your brand
          </div>
          <Link className="text-white hover:text-pri-500 hover:bg-white  border-2 border-white  font-semibold py-3 px-5 rounded-xl inline-block">
            <div className="flex items-center gap-2">
              Get Started
              <i class="ri-arrow-right-line"></i>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Banner;
