import React from "react";
import { Link } from "react-router-dom";
import bill from "../../assets/images/bill.jpg";

function ItemCard() {
  return (
    <Link
      to="/single-item/item"
      className="text-base text-sec-400 hover:text-sec-400"
    >
      <div className="h-52 relative rounded-xl overflow-hidden mb-2">
        <img
          className="absolute top-0 left-0 w-full h-full object-cover "
          src={bill}
          alt="billboard"
        />
      </div>
      <div className="flex items-center justify-between font-semibold text-sec-500">
        <div>Toronto, Canada</div>
        <div className="flex items-center gap-1">
          <i className="ri-star-fill"></i>4.95
        </div>
      </div>
      <div>LED Screen</div>
      <div className="mb-2">
        <span className="font-semibold">$ 500</span> / month
      </div>
    </Link>
  );
}

export default ItemCard;
