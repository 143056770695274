import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/layout/Footer";
import ItemsNavBar from "../components/layout/ItemsNavBar";
import NavBar from "../components/layout/NavBar";

function WebLayout() {
  return (
    <>
      <NavBar />
      <ItemsNavBar />
      <Outlet />
      <Footer />
    </>
  );
}

export default WebLayout;
