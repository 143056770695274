import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Slider from "react-slick";

import all from "../../assets/icons/all.png";
import tv from "../../assets/icons/television.png";
import led from "../../assets/icons/led.png";
import sign from "../../assets/icons/sign.png";
import billboard from "../../assets/icons/billboard.png";
import nameboard from "../../assets/icons/nameboard.png";
import busStop from "../../assets/icons/bus-stop.png";
import facebook from "../../assets/icons/facebook.png";
import instagram from "../../assets/icons/instagram.png";
import twitter from "../../assets/icons/twitter.png";
import youtube from "../../assets/icons/youtube.png";
import website from "../../assets/icons/website.png";
import newspaper from "../../assets/icons/newspaper.png";
import magazine from "../../assets/icons/magazine.png";
import van from "../../assets/icons/car.png";
import threewheeler from "../../assets/icons/threewheeler.png";
import tshirt from "../../assets/icons/tshirt.png";
import FilterModal from "../modals/FilterModal";

function FilterNavBar() {
  const [scrolled, setScrolled] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    window.addEventListener("scroll", (event) => {
      if (window.scrollY > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    });
  }, []);
  var settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 12,
    slidesToScroll: 10,
    initialSlide: 0,

    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 3,
    //       infinite: true,
    //       dots: true,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 2,
    //       initialSlide: 2,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
  };
  return (
    <>
      <div
        className={
          " pt-20 sticky top-0 z-20 bg-white " + (scrolled && "shadow-sm")
        }
      >
        <div className="container">
          <div className="flex items-center gap-10 justify-between w-full">
            <div className="w-full" style={{ width: "87%" }}>
              <Slider {...settings}>
                <Link
                  to="/app/all-items"
                  className="py-3  cursor-pointer border-b-2 border-transparent hover:border-sec-400 opacity-60 hover:opacity-100"
                >
                  <img className="h-6 mx-auto mb-1 " src={all} alt="sample" />
                  <div className="text-center text-xs text-sec-500">
                    All Items
                  </div>
                </Link>
                <Link className="py-3  cursor-pointer border-b-2 border-transparent hover:border-sec-400 opacity-60 hover:opacity-100">
                  <img className="h-6 mx-auto mb-1" src={tv} alt="sample" />
                  <div className="text-center text-xs text-sec-500">
                    TV Screen
                  </div>
                </Link>
                <Link
                  to="/app/led-screens"
                  className="py-3  cursor-pointer border-b-2 border-transparent hover:border-sec-400 opacity-60 hover:opacity-100"
                >
                  <img className="h-6 mx-auto mb-1" src={led} alt="sample" />
                  <div className="text-center text-xs text-sec-500">
                    LED Screen
                  </div>
                </Link>
                <Link className="py-3  cursor-pointer border-b-2 border-transparent hover:border-sec-400 opacity-60 hover:opacity-100">
                  <img className="h-6 mx-auto mb-1 " src={sign} alt="sample" />
                  <div className="text-center text-xs text-sec-500">
                    Signage
                  </div>
                </Link>
                <Link className="py-3  cursor-pointer border-b-2 border-transparent hover:border-sec-400 opacity-60 hover:opacity-100">
                  <img
                    className="h-6 mx-auto mb-1 "
                    src={billboard}
                    alt="sample"
                  />
                  <div className="text-center text-xs text-sec-500">
                    Bill Boards
                  </div>
                </Link>
                <Link className="py-3  cursor-pointer border-b-2 border-transparent hover:border-sec-400 opacity-60 hover:opacity-100">
                  <img
                    className="h-6 mx-auto mb-1"
                    src={nameboard}
                    alt="sample"
                  />
                  <div className="text-center text-xs text-sec-500">
                    Name Boards
                  </div>
                </Link>
                <Link className="py-3  cursor-pointer border-b-2 border-transparent hover:border-sec-400 opacity-60 hover:opacity-100">
                  <img
                    className="h-6 mx-auto mb-1"
                    src={busStop}
                    alt="sample"
                  />
                  <div className="text-center text-xs text-sec-500">
                    Bus Halts
                  </div>
                </Link>
                <Link className="py-3  cursor-pointer border-b-2 border-transparent hover:border-sec-400 opacity-60 hover:opacity-100">
                  <img
                    className="h-6 mx-auto mb-1"
                    src={facebook}
                    alt="sample"
                  />
                  <div className="text-center text-xs text-sec-500">
                    Facebook
                  </div>
                </Link>
                <Link className="py-3  cursor-pointer border-b-2 border-transparent hover:border-sec-400 opacity-60 hover:opacity-100">
                  <img
                    className="h-6 mx-auto mb-1"
                    src={instagram}
                    alt="sample"
                  />
                  <div className="text-center text-xs text-sec-500">
                    Instagram
                  </div>
                </Link>
                <Link className="py-3  cursor-pointer border-b-2 border-transparent hover:border-sec-400 opacity-60 hover:opacity-100">
                  <img
                    className="h-6 mx-auto mb-1"
                    src={twitter}
                    alt="sample"
                  />
                  <div className="text-center text-xs text-sec-500">
                    Twitter
                  </div>
                </Link>
                <Link className="py-3  cursor-pointer border-b-2 border-transparent hover:border-sec-400 opacity-60 hover:opacity-100">
                  <img
                    className="h-6 mx-auto mb-1"
                    src={youtube}
                    alt="sample"
                  />
                  <div className="text-center text-xs text-sec-500">
                    YouTube
                  </div>
                </Link>
                <Link className="py-3  cursor-pointer border-b-2 border-transparent hover:border-sec-400 opacity-60 hover:opacity-100">
                  <img
                    className="h-6 mx-auto mb-1 "
                    src={website}
                    alt="sample"
                  />
                  <div className="text-center text-xs text-sec-500">
                    Website
                  </div>
                </Link>
                <Link className="py-3  cursor-pointer border-b-2 border-transparent hover:border-sec-400 opacity-60 hover:opacity-100">
                  <img
                    className="h-6 mx-auto mb-1"
                    src={newspaper}
                    alt="sample"
                  />
                  <div className="text-center text-xs text-sec-500">
                    Newspaper
                  </div>
                </Link>
                <Link className="py-3  cursor-pointer border-b-2 border-transparent hover:border-sec-400 opacity-60 hover:opacity-100">
                  <img
                    className="h-6 mx-auto mb-1 "
                    src={magazine}
                    alt="sample"
                  />
                  <div className="text-center text-xs text-sec-500">
                    Magazine
                  </div>
                </Link>
                <Link className="py-3  cursor-pointer border-b-2 border-transparent hover:border-sec-400 opacity-60 hover:opacity-100">
                  <img className="h-6 mx-auto mb-1" src={van} alt="sample" />
                  <div className="text-center text-xs text-sec-500">
                    Van/Car
                  </div>
                </Link>
                <Link className="py-3  cursor-pointer border-b-2 border-transparent hover:border-sec-400 opacity-60 hover:opacity-100">
                  <img
                    className="h-6 mx-auto mb-1"
                    src={threewheeler}
                    alt="sample"
                  />
                  <div className="text-center text-xs text-sec-500">
                    Three Wheeler
                  </div>
                </Link>
                <Link className="py-3  cursor-pointer border-b-2 border-transparent hover:border-sec-400 opacity-60 hover:opacity-100">
                  <img className="h-6 mx-auto mb-1" src={tshirt} alt="sample" />
                  <div className="text-center text-xs text-sec-500">
                    T-Shirts
                  </div>
                </Link>
              </Slider>
            </div>
            <div
              onClick={() => setOpenFilter(true)}
              className="px-5 py-2.5 rounded-xl flex items-center gap-2 cursor-pointer mb-1"
              style={{ border: "1px solid #d6d6d6" }}
            >
              <i className="ri-equalizer-fill"></i>
              Filter
            </div>
          </div>
        </div>
      </div>
      <FilterModal
        visible={openFilter}
        handleClose={() => setOpenFilter(false)}
      />
    </>
  );
}

export default FilterNavBar;
