import { Form, Input, Modal, Select, Slider } from "antd";
import React, { useState } from "react";

function FilterModal(props) {
  const BudgetSlider = () => {
    const [inputValue, setInputValue] = useState([20, 50]);
    const onChange = (newValue) => {
      setInputValue(newValue);
    };
    return (
      <div className="grid grid-cols-3 gap-5">
        <Slider
          className="col-span-2"
          range
          step={10}
          defaultValue={[20, 50]}
          onChange={onChange}
        />

        <Input
          style={{ pointerEvents: "none" }}
          value={`${inputValue[0]}$ - ${inputValue[1]}$ `}
        />
      </div>
    );
  };
  const SizeSlider = () => {
    const [inputSize, setInputSize] = useState([200, 300]);
    const onChangeSize = (newValue) => {
      setInputSize(newValue);
    };
    return (
      <div className="grid grid-cols-3 gap-5">
        <Slider
          className="col-span-2"
          range
          min={0}
          max={500}
          step={10}
          defaultValue={[200, 300]}
          onChange={onChangeSize}
        />

        <Input
          style={{ pointerEvents: "none" }}
          value={`${inputSize[0]} inches - ${inputSize[1]} inches `}
        />
      </div>
    );
  };
  const RatingSlider = () => {
    const [inputRating, setInputRating] = useState([3, 5]);
    const onChangeRating = (newValue) => {
      setInputRating(newValue);
    };
    return (
      <div className="grid grid-cols-3 gap-5">
        <Slider
          className="col-span-2"
          range
          min={0}
          max={5}
          step={0.01}
          defaultValue={[3, 5]}
          onChange={onChangeRating}
        />

        <Input
          style={{ pointerEvents: "none" }}
          value={`${inputRating[0]} stars - ${inputRating[1]} stars `}
        />
      </div>
    );
  };

  return (
    <Modal
      title="Filters"
      open={props.visible}
      onCancel={props.handleClose}
      width={600}
      bodyStyle={{ padding: "0" }}
      footer={[
        <div className="flex justify-between items-center">
          <div className="text-base font-semibold">Clear all</div>
          <div
            onClick={props.handleClose}
            className="inline-block text-sm font-semibold  py-3 px-5 rounded-lg bg-pri-500 hover:bg-sec-500 cursor-pointer text-white hover:text-white text-center"
          >
            Show 49 Items
          </div>
        </div>,
      ]}
    >
      <div className="overflow-y-scroll p-6 " style={{ height: "60vh" }}>
        <Form name="basic" layout="vertical" requiredMark={false}>
          <div className="text-xl font-semibold ">Basic Filters</div>
          <div
            className="my-5"
            style={{ borderBottom: "1px solid #d6d6d6" }}
          ></div>
          <Form.Item label="Type" name="type">
            <Select defaultValue="What are you looking for?">
              <Select.Option value="Custom Production">All Items</Select.Option>
              <Select.Option value="Custom Production">
                TV Screens
              </Select.Option>
              <Select.Option value="Custom Production">
                LED Screens
              </Select.Option>
              <Select.Option value="Custom Production">YouTube</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Location" name="location">
            <Select defaultValue="Your desired location?">
              <Select.Option value="Custom Production">Toronto</Select.Option>
              <Select.Option value="Custom Production">Buffalo</Select.Option>
              <Select.Option value="Custom Production">Hamilton</Select.Option>
              <Select.Option value="Custom Production">Markham</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Per day budget " name="budget">
            <BudgetSlider />
          </Form.Item>
          <div className="text-xl font-semibold ">Advanced Filters</div>
          <div
            className="my-5"
            style={{ borderBottom: "1px solid #d6d6d6" }}
          ></div>{" "}
          <Form.Item label="Ratings " name="budget">
            <RatingSlider />
          </Form.Item>
          <Form.Item label="Size in inches " name="budget">
            <SizeSlider />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}

export default FilterModal;
