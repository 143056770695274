import React, { useState } from "react";
import { Link } from "react-router-dom";
import sampleImage from "../../assets/images/bill.jpg";
import ItemCard from "../../components/app/ItemCard";
import CalendarModal from "../../components/modals/CalendarModal";

function SingleItemPage() {
  const [openCalendar, setOpenCalendar] = useState(false);
  return (
    <>
      <div className="py-5   px-3 ">
        <div className="container">
          <div className="flex items-center gap-3 mb-3">
            <Link
              to=""
              className="flex items-center gap-1 text-xl text-sec-500 hover:text-pri-500"
            >
              <i class="ri-home-3-fill"></i> Home
            </Link>
            <div className="text-xl text-sec-500">/</div>
            <Link to="" className=" text-xl text-sec-500  hover:text-pri-500">
              LED Screens
            </Link>
            <div className="text-xl text-sec-500">/</div>
            <div className="text-xl text-sec-500 font-semibold">
              Paradise City Mall
            </div>
          </div>
          <div className="rounded-xl overflow-hidden">
            <div className="grid grid-cols-2 gap-2">
              <div className="h-80 relative overflow-hidden">
                <img
                  className="absolute top-0 left-0 w-full h-full object-cover "
                  src={sampleImage}
                  alt="sample"
                />
              </div>
              <div className="grid grid-cols-2 gap-2">
                {" "}
                <div className="h-full relative overflow-hidden">
                  <img
                    className="absolute top-0 left-0 w-full h-full object-cover "
                    src={sampleImage}
                    alt="sample"
                  />
                </div>{" "}
                <div className="h-full relative overflow-hidden">
                  <img
                    className="absolute top-0 left-0 w-full h-full object-cover "
                    src={sampleImage}
                    alt="sample"
                  />
                </div>{" "}
                <div className="h-full relative overflow-hidden">
                  <img
                    className="absolute top-0 left-0 w-full h-full object-cover "
                    src={sampleImage}
                    alt="sample"
                  />
                </div>{" "}
                <div className="h-full relative overflow-hidden">
                  <img
                    className="absolute top-0 left-0 w-full h-full object-cover "
                    src={sampleImage}
                    alt="sample"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="py-5   px-3 ">
        <div className="container">
          <div className="grid grid-cols-3 gap-10">
            <div className="col-span-2">
              <div className="text-4xl font-semibold text-sec-500 mb-2">
                Paradise City Mall
              </div>

              <div className="flex items-center gap-2 text-base text-sec-400">
                <div className="flex items-center gap-1">
                  <i className="ri-star-fill"></i>4.92
                </div>
                <div>29 reviews</div>
                <div>Toronto, Canada</div>
              </div>
              <div
                className="my-5"
                style={{ borderBottom: "1px solid #d6d6d6" }}
              ></div>
              <div className="text-xl font-semibold mb-1">Description</div>
              <div className="text-base text-sec-400 mb-5">
                150 nos. of 15 second commercials per day only for Rs.100,000
                per month Size 20x10 feet Vehicles monthly pass by -more than
                650,000 vehicles Vehicles daily pass by -more than 15,000
                vehicles
              </div>
              <div className="text-xl font-semibold mb-1">Specification</div>
              <div className="mb-5">Table here</div>
              <div className="text-xl font-semibold mb-1">Reviews</div>
              <div className="mb-5">Reviews here</div>
              <div className="text-xl font-semibold mb-1">Location</div>
              <div className=" rounded-xl overflow-hidden">
                <iframe
                  className="w-full h-80"
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=royal%20ontario%20musium&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                  title="location"
                ></iframe>
              </div>
            </div>
            <div
              className="p-5 rounded-xl shadow-xl h-fit text-base sticky top-32"
              style={{
                border: "1px solid #d6d6d6",
                boxShadow: "rgba(149, 157, 165, 0.3) 0px 8px 24px",
              }}
            >
              <div className="flex items-center justify-between">
                <div>LED Screen</div>

                <div>
                  <span className="font-semibold">$50</span> day
                </div>
              </div>
              <div className="text-lg font-semibold">Paradise City Mall</div>
              <div>
                Item code : <span className="text-sm"> PRD-AT-O-JAFF</span>
              </div>
              <div
                onClick={() => setOpenCalendar(true)}
                className="grid grid-cols-2 rounded-lg p-2 my-4 cursor-pointer"
                style={{
                  border: "1px solid #d6d6d6",
                }}
              >
                <div>
                  <div className="text-sm">Start date</div>
                  <div>15/Nov/2022</div>
                </div>
                <div>
                  <div className="text-sm">End date</div>
                  <div>16/Dec/2022</div>
                </div>
              </div>

              <Link
                to="/single-item/place-order"
                className="inline-block w-full text-sm font-semibold mb-2 py-3 px-5 rounded-lg bg-pri-500 hover:bg-sec-500 cursor-pointer text-white hover:text-white text-center"
              >
                Reserve Now
              </Link>

              <div className="text-center mb-5">You won't be charged yet</div>
              <div className="flex items-center justify-between">
                <div>$50 * 30 days</div>
                <div>$1500</div>
              </div>
              <div className="flex items-center justify-between">
                <div>Designing fee</div>
                <div>$100</div>
              </div>
              <div
                className="my-3"
                style={{ borderBottom: "1px solid #d6d6d6" }}
              ></div>
              <div className="flex items-center justify-between font-semibold">
                <div>Total amount</div>
                <div>$1600</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-5   px-3 ">
        <div className="container">
          <div className=" text-4xl font-semibold text-sec-500 mb-5">
            Recomended Items
          </div>
          <div className="grid grid-cols-4 gap-5">
            <ItemCard />
            <ItemCard />
            <ItemCard />
            <ItemCard />
          </div>
        </div>
      </div>
      <CalendarModal
        visible={openCalendar}
        handleClose={() => setOpenCalendar(false)}
      />
    </>
  );
}

export default SingleItemPage;
