import React from "react";
import video from "../../assets/video/video.mp4";

function WhatWeDo() {
  return (
    <div className="py-12 sm:py-16 md:py-20 px-3 ">
      <div className="container">
        <div className="grid grid-cols-2 gap-10 mb-20 items-center">
          <div>
            <div className="text-4xl font-bold mb-5">
              BRAND helps small and medium sized businesses grow
            </div>
            <div className="text-xl text-sec-300">
              In publishing and graphic design, Lorem ipsum is a placeholder
              text commonly used to demonstrate the visual form of a document or
              a typeface without relying on meaningful content. Lorem ipsum may
              be used as a placeholder before final copy is available.
            </div>
          </div>
          <div>
            <video autoPlay muted loop>
              <source src={video} type="video/mp4" />
            </video>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-10">
          <div>
            <i className="ri-map-pin-user-fill text-6xl"></i>
            <div className="text-xl font-semibold">
              Help Your Audience Find You
            </div>
            <div className="text-base text-sec-300">
              Digital billboards help with the first step of your marketing
              funnel, brand awareness.
            </div>
          </div>
          <div>
            <i className="ri-advertisement-fill text-6xl"></i>
            <div className="text-xl font-semibold">
              Advertise When and Where You Want
            </div>
            <div className="text-base text-sec-300">
              Blip allows you to choose your desired locations, set your
              schedule, and adjust your budget to match your marketing needs.
            </div>
          </div>
          <div>
            <i className="ri-volume-up-fill text-6xl"></i>
            <div className="text-xl font-semibold">
              Improve Your Marketing Funnel Conversion Rate
            </div>
            <div className="text-base text-sec-300">
              Adding different channels to your marketing improves conversion
              and creates more leads.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatWeDo;
