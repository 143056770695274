import { Steps } from "antd";
import React from "react";
import sampleImage from "../../assets/images/homepage-hero@1x.avif";
function HowItWorks() {
  return (
    <div className="py-12 sm:py-16 md:py-20 px-3 ">
      <div className="container">
        <div className="grid grid-cols-2 gap-20">
          <div>
            <div className="text-4xl font-bold mb-5">How BRAND Works</div>
            <Steps
              direction="vertical"
              items={[
                {
                  title: (
                    <div className="text-xl font-semibold text-sec-500">
                      Select Your Billboard Type
                    </div>
                  ),
                  description: (
                    <div className="text-base text-sec-300">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form of a document..
                    </div>
                  ),
                },
                {
                  title: (
                    <div className="text-xl font-semibold text-sec-500">
                      Pick Your Billboard Locations
                    </div>
                  ),
                  description: (
                    <div className="text-base text-sec-300">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form of a document..
                    </div>
                  ),
                },
                {
                  title: (
                    <div className="text-xl font-semibold text-sec-500">
                      Set Your Budget & Schedule
                    </div>
                  ),
                  description: (
                    <div className="text-base text-sec-300">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form of a document..
                    </div>
                  ),
                },
                {
                  title: (
                    <div className="text-xl font-semibold text-sec-500">
                      Upload Your Artwork
                    </div>
                  ),
                  description: (
                    <div className="text-base text-sec-300">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form of a document..
                    </div>
                  ),
                },
                {
                  title: (
                    <div className="text-xl font-semibold text-sec-500">
                      Wait for Approval
                    </div>
                  ),
                  description: (
                    <div className="text-base text-sec-300">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form of a document..
                    </div>
                  ),
                },
              ]}
            />
          </div>
          <div className="mx-auto">
            <img style={{ height: "600px" }} src={sampleImage} alt="diagram" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;
