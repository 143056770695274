import { Avatar, Dropdown, Form, Menu, Select, Slider, Input } from "antd";

import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import SignupModal from "../modals/SignupModal";

function NavBar() {
  const [showSearch, setShowSearch] = useState(false);
  const [openSignup, setOpenSignup] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [shadow, setShadow] = useState(false);
  const [modal, setModal] = useState("");
  const moreMenu = (
    <Menu
      style={{ width: "250px", borderRadius: "12px", paddingBlock: "12px" }}
      items={[
        {
          key: "1",
          label: (
            <NavLink
              style={({ isActive }) => {
                return { color: isActive ? "#1890ff" : "#26272b" };
              }}
              to=""
              className="text-base text-sec-500 hover:text-pri-500 px-3 py-1 mt-2 "
            >
              About Us
            </NavLink>
          ),
        },
        {
          key: "2",
          label: (
            <NavLink
              style={({ isActive }) => {
                return { color: isActive ? "#1890ff" : "#26272b" };
              }}
              to=""
              className="text-base text-sec-500 hover:text-pri-500 px-3 py-1 mt-2 "
            >
              Contact Us
            </NavLink>
          ),
        },
        {
          key: "3",
          label: (
            <NavLink
              style={({ isActive }) => {
                return { color: isActive ? "#1890ff" : "#26272b" };
              }}
              to=""
              className="text-base text-sec-500 hover:text-pri-500 px-3 py-1 mt-2 "
            >
              Help
            </NavLink>
          ),
        },
        {
          key: "4",
          label: <div style={{ borderBottom: "1px solid #d6d6d6" }}></div>,
        },

        {
          key: "5",
          label: (
            <NavLink
              style={({ isActive }) => {
                return { color: isActive ? "#1890ff" : "#26272b" };
              }}
              to=""
              className="text-base font-semibold
               text-sec-500 hover:text-pri-500 px-3 py-1 mt-2 "
            >
              Become a Partner
            </NavLink>
          ),
        },
      ]}
    />
  );
  const profileMenu = (
    <Menu
      style={{ width: "250px", borderRadius: "12px", paddingBlock: "12px" }}
    >
      <div className="p-2 text-center">
        <Avatar size={64}>T</Avatar>
      </div>
      <div className="text-base text-sec-500 hover:text-pri-500 px-5 py-1  cursor-pointer">
        Logout
      </div>
      <div className="text-base text-sec-500 hover:text-pri-500 px-5 py-1  cursor-pointer ">
        My Orders
      </div>
    </Menu>
  );
  const BudgetSlider = () => {
    const [inputValue, setInputValue] = useState([20, 50]);
    const onChange = (newValue) => {
      setInputValue(newValue);
    };
    return (
      <div className="grid grid-cols-3">
        <Slider
          className="col-span-2"
          range
          step={10}
          defaultValue={[20, 50]}
          onChange={onChange}
        />

        <Input
          style={{ pointerEvents: "none" }}
          value={`$${inputValue[0]} - $${inputValue[1]} `}
        />
      </div>
    );
  };
  return (
    <>
      <div
        style={{ width: "100vw " }}
        className={"bg-white  z-40  fixed " + (shadow && "thin--shadow")}
      >
        <div>
          <div className="container">
            <div className="grid grid-cols-3 items-center h-20 ">
              <Link
                to="/home"
                className="font-bold text-xl text-sec-500 hover:text-sec-500 cursor-pointer"
              >
                BRAND
              </Link>
              <div
                className="flex items-center  p-1 rounded-xl cursor-pointer w-fit justify-self-center"
                style={{ border: "1px solid #d6d6d6" }}
                onClick={() => {
                  setShowSearch(true);
                  setShadow(true);
                }}
              >
                <div className="px-3">Type</div>
                <div
                  className="px-3"
                  style={{
                    borderLeft: "1px solid #d6d6d6",
                    borderRight: "1px solid #d6d6d6",
                  }}
                >
                  Location
                </div>
                <div className="px-3">Budget</div>
                <div className="px-3">
                  <div
                    className="h-8 w-8 font-semibold text-white flex items-center justify-center rounded-full "
                    style={{ backgroundColor: "#1890ff" }}
                  >
                    <i className="ri-search-line"></i>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-5 justify-self-end">
                <Dropdown placement="bottomRight" overlay={moreMenu}>
                  <div className="text-base font-semibold flex items-center gap-1 cursor-pointer">
                    <div>More</div>
                    <i className="ri-arrow-down-s-line mt-1"></i>
                  </div>
                </Dropdown>
                {isLoggedIn ? (
                  <Dropdown
                    placement="bottomRight"
                    // trigger={["click"]}
                    overlay={profileMenu}
                  >
                    <div
                      className="flex items-center gap-3  p-1 rounded-xl cursor-pointer"
                      style={{ border: "1px solid #d6d6d6" }}
                    >
                      <i className="ri-menu-line text-lg"></i>
                      <Avatar />
                    </div>
                  </Dropdown>
                ) : (
                  <div className="flex gap-5 items-center">
                    <div
                      onClick={() => {
                        setOpenSignup(true);
                        setModal("login");
                      }}
                      className="text-base font-semibold cursor-pointer"
                    >
                      Log in
                    </div>
                    <div
                      onClick={() => {
                        setOpenSignup(true);
                        setModal("signup");
                      }}
                      className="text-white hover:text-white bg-pri-500 hover:bg-sec-500 font-semibold py-3 px-5 rounded-xl cursor-pointer"
                    >
                      Sign up
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={showSearch ? "block" : "hidden"}>
          <div className="container">
            <Form name="basic" layout="vertical">
              <div className="grid grid-cols-4 items-center gap-5">
                <Form.Item label="What type" name="type">
                  <Select defaultValue="What are you looking for?">
                    <Select.Option value="Custom Production">
                      All Items
                    </Select.Option>
                    <Select.Option value="Custom Production">
                      TV Screens
                    </Select.Option>
                    <Select.Option value="Custom Production">
                      LED Screens
                    </Select.Option>
                    <Select.Option value="Custom Production">
                      YouTube
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item label="Location" name="location">
                  <Select defaultValue="Your desired location?">
                    <Select.Option value="Custom Production">
                      Toronto
                    </Select.Option>
                    <Select.Option value="Custom Production">
                      Buffalo
                    </Select.Option>
                    <Select.Option value="Custom Production">
                      Hamilton
                    </Select.Option>
                    <Select.Option value="Custom Production">
                      Markham
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item label="Per day budget " name="budget">
                  <BudgetSlider />
                </Form.Item>

                <Form.Item>
                  <div className="flex justify-between  items-center ">
                    <Link
                      onClick={() => setShowSearch(false)}
                      to="/single-item/items"
                      className="mt-4  text-sm font-semibold py-3 px-5 rounded-xl  flex items-end gap-2 text-white hover:text-white bg-pri-500 hover:bg-sec-500"
                    >
                      <i className="ri-search-line"></i> Search
                    </Link>
                    <button
                      className="h-10 w-10 rounded-full   font-semibold text-xl  flex items-center justify-center gap-2"
                      style={{ border: "1px solid #d6d6d6" }}
                      onClick={() => {
                        setShowSearch(false);
                        setShadow(false);
                      }}
                    >
                      <i className="ri-close-line"></i>
                    </button>
                  </div>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div>
        <SignupModal
          visible={openSignup}
          handleClose={() => setOpenSignup(false)}
          modal={modal}
          changeModalLogin={() => setModal("login")}
          changeModalSignup={() => setModal("signup")}
        />
      </div>
    </>
  );
}

export default NavBar;
