import React from "react";
import { Outlet } from "react-router-dom";
import FilterNavBar from "../components/layout/FilterNavBar";
import Footer from "../components/layout/Footer";
import NavBar from "../components/layout/NavBar";

function AppLayout() {
  return (
    <>
      <NavBar />
      <FilterNavBar />
      <Outlet />
      <Footer />
    </>
  );
}

export default AppLayout;
