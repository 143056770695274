import React from "react";
import ItemCard from "../../components/app/ItemCard";

function AllItems() {
  return (
    <div className="py-12 sm:py-16 px-3 ">
      <div className="container">
        <div className="grid grid-cols-4 gap-5">
          <ItemCard />
          <ItemCard />
          <ItemCard />
          <ItemCard />
        </div>
      </div>
    </div>
  );
}

export default AllItems;
