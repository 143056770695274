import React from "react";
import { Link } from "react-router-dom";
import heroImage from "../../assets/images/billboard3.png";

function Hero() {
  return (
    <div className="py-12 sm:py-16 md:py-20 px-3 ">
      <div className="container">
        <div className="grid grid-cols-2 items-center gap-10">
          <div>
            <img className="h-96" src={heroImage} alt="hero" />
          </div>
          <div>
            <div className="text-5xl font-bold mb-5 text-sec-500">
              Reach new customers in a big way
            </div>
            <div className="text-xl mb-5 text-sec-300">
              Expand your marketing mix and grow your business with digital
              billboard space anytime, anywhere, on any budget. Over 2,000
              locations and counting.
            </div>

            <Link className="text-white hover:text-white bg-pri-500 hover:bg-sec-500 font-semibold py-3 px-5 rounded-xl inline-block">
              <div className="flex items-center gap-2">
                Let's Talk
                <i class="ri-arrow-right-line"></i>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
