import { Modal } from "antd";
import React from "react";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

function CalendarModal(props) {
  return (
    <Modal
      title="Edit Dates"
      open={props.visible}
      footer={null}
      onCancel={props.handleClose}
      width={600}
    >
      <div className="text-xl font-semibold">30 days</div>
      <div className="grid grid-cols-3 items-center gap-5">
        <RangePicker className="col-span-2 w-full" />

        <div
          onClick={props.handleClose}
          className="inline-block w-full text-sm font-semibold  py-3 px-5 rounded-lg bg-pri-500 hover:bg-sec-500 cursor-pointer text-white hover:text-white text-center"
        >
          Change Dates
        </div>
      </div>
    </Modal>
  );
}

export default CalendarModal;
