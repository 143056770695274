import React, { useState } from "react";
import { Form, Input, Modal, Steps, Upload } from "antd";
import { Link } from "react-router-dom";
import sampleImage from "../../assets/images/bill.jpg";
import TextArea from "antd/lib/input/TextArea";
import CalendarModal from "../../components/modals/CalendarModal";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

function PlaceOrder() {
  const [step, setStep] = useState(1);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [openCalendar, setOpenCalendar] = useState(false);
  const [fileList, setFileList] = useState([
    {
      uid: "-1",
      name: "image.png",
      status: "done",
      url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    },
    {
      uid: "-2",
      name: "image.png",
      status: "done",
      url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    },
    {
      uid: "-3",
      name: "image.png",
      status: "done",
      url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    },
    {
      uid: "-4",
      name: "image.png",
      status: "done",
      url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    },
    {
      uid: "-xxx",
      percent: 50,
      name: "image.png",
      status: "uploading",
      url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    },
    {
      uid: "-5",
      name: "image.png",
      status: "error",
    },
  ]);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      {/* <PlusOutlined /> */}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  const orderForm = (
    <div
      className="p-5 rounded-xl shadow-xl h-fit text-base sticky top-32"
      style={{
        border: "1px solid #d6d6d6",
        boxShadow: "rgba(149, 157, 165, 0.3) 0px 8px 24px",
      }}
    >
      <div className="flex items-center justify-between">
        <div>LED Screen</div>

        <div>
          <span className="font-semibold">$50</span> day
        </div>
      </div>
      <div className="text-lg font-semibold">Paradise City Mall</div>
      <div>
        Item code : <span className="text-sm"> PRD-AT-O-JAFF</span>
      </div>
      <div
        className="grid grid-cols-2 rounded-lg p-2 my-4 cursor-pointer"
        style={{
          border: "1px solid #d6d6d6",
        }}
        onClick={() => setOpenCalendar(true)}
      >
        <div>
          <div className="text-sm">Start date</div>
          <div>15/Nov/2022</div>
        </div>
        <div>
          <div className="text-sm">End date</div>
          <div>16/Dec/2022</div>
        </div>
      </div>

      <div className="inline-block w-full text-sm font-semibold mb-2 py-3 px-5 rounded-lg bg-pri-500 hover:bg-sec-500 cursor-pointer text-white hover:text-white text-center">
        {step === 1 && (
          <div onClick={() => setStep(2)}>Proceed to Checkout</div>
        )}
        {step === 2 && <div onClick={() => setStep(3)}>Pay Now</div>}
      </div>

      <div className="text-center mb-5">You won't be charged yet</div>
      <div className="flex items-center justify-between">
        <div>$50 * 30 days</div>
        <div>$1500</div>
      </div>
      <div className="flex items-center justify-between">
        <div>Designing fee</div>
        <div>$100</div>
      </div>
      <div className="my-3" style={{ borderBottom: "1px solid #d6d6d6" }}></div>
      <div className="flex items-center justify-between font-semibold">
        <div>Total amount</div>
        <div>$1600</div>
      </div>
    </div>
  );

  const description = "This is a description.";
  const items = [
    {
      title: (
        <div
          onClick={() => setStep(1)}
          className="text-lg font-semibold cursor-pointer"
        >
          Order
        </div>
      ),
      description,
    },
    {
      title: (
        <div
          onClick={() => setStep(2)}
          className="text-lg font-semibold cursor-pointer"
        >
          Checkout
        </div>
      ),
      description,
    },
    {
      title: (
        <div
          onClick={() => setStep(3)}
          className="text-lg font-semibold cursor-pointer"
        >
          Complete
        </div>
      ),
      description,
    },
  ];
  const order = (
    <div className="grid grid-cols-3 gap-10">
      <div className="col-span-2">
        <div className="text-4xl font-semibold text-sec-500 mb-2">
          Your Order
        </div>

        {/* <div className="flex items-center gap-2 text-base text-sec-400">
          <div className="flex items-center gap-1">
            <i className="ri-star-fill"></i>4.92
          </div>
          <div>29 reviews</div>
          <div>Toronto, Canada</div>
        </div> */}
        <div
          className="my-5"
          style={{ borderBottom: "1px solid #d6d6d6" }}
        ></div>
        <div className="mb-5">
          <div className="text-xl font-semibold mb-1">Details</div>
          <div className="relative my-3 h-40 w-56 overflow-hidden">
            <img
              className="absolute w-full h-full top-0 left-0 object-cover"
              src={sampleImage}
              alt="sample"
            />
          </div>
          <div className="text-base text-sec-400 ">
            <span className="font-semibold">Name :</span> Paradise City Mall
          </div>
          <div className="text-base text-sec-400 ">
            <span className="font-semibold">Type :</span> LED Screen
          </div>
          <div className="text-base text-sec-400 ">
            <span className="font-semibold">Location :</span> 75 Bremner Blvd,
            Toronto, Ontario, M5J 0A1
          </div>
        </div>
        <div className="mb-5">
          <div className="text-xl font-semibold mb-1">Dates</div>
          <div className="text-base text-sec-400 ">
            <span className="font-semibold">From :</span> 15 Nov 2022
          </div>
          <div className="text-base text-sec-400 ">
            <span className="font-semibold">To :</span> 16 Dec 2022
          </div>
        </div>
        <div
          className="my-5"
          style={{ borderBottom: "1px solid #d6d6d6" }}
        ></div>
        <div className="mb-5">
          <div className="text-xl font-semibold mb-4">Upload your Designs</div>
          <Upload
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
          >
            {fileList.length >= 8 ? null : uploadButton}
          </Upload>
          <div>
            * Please Upload PNG, JPEG, SVG image formats with high quality and
            Lorem ipsum is a placeholder text commonly used to demonstrate the
            visual form of a document or a typeface
          </div>
          <Modal
            open={previewOpen}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img
              alt="example"
              style={{
                width: "100%",
              }}
              src={previewImage}
            />
          </Modal>
        </div>
        <div
          className="my-5"
          style={{ borderBottom: "1px solid #d6d6d6" }}
        ></div>
        <div className="mb-5">
          <div className="text-xl font-semibold mb-4">Special notes</div>
          <TextArea rows={5} placeholder="Type notes if any" maxLength={6} />
        </div>
      </div>
      {orderForm}
    </div>
  );

  const checkout = (
    <div className="grid grid-cols-3 gap-10">
      <div className="col-span-2">
        <div className="text-4xl font-semibold text-sec-500 mb-2">Checkout</div>
        <div
          className="my-5"
          style={{ borderBottom: "1px solid #d6d6d6" }}
        ></div>
        <div className="mb-5">
          <div className="text-xl font-semibold mb-4">Billing Details</div>
          <Form name="basic" layout="vertical" requiredMark={false}>
            <div className="grid grid-cols-2 items-center gap-5">
              <Form.Item
                label="*Firstname"
                name="firstname"
                rules={[
                  {
                    required: true,
                    message: "Please input your firstname!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="*Lastname"
                name="lastname"
                rules={[
                  {
                    required: true,
                    message: "Please input your lastname!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="grid grid-cols-2 items-center gap-5">
              <Form.Item
                label="*Phone"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="*Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <Form.Item
              label="*Company name"
              name="company"
              rules={[
                {
                  required: true,
                  message: "Please input your company name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Special note"
              name="note"
              rules={[
                {
                  required: true,
                  message: "Please input your note if any!",
                },
              ]}
            >
              <TextArea rows={5} />
            </Form.Item>
          </Form>
        </div>
      </div>
      {orderForm}
    </div>
  );

  const complete = (
    <div className="text-3xl text-center max-w-4xl mx-auto mb-10">
      Congratulation! You’ve completed your booking. One of our executives will
      contact you shortly
    </div>
  );

  return (
    <>
      <div className="py-5   px-3 ">
        <div className="container">
          <div className="flex items-center gap-3 mb-3">
            <Link
              to=""
              className="flex items-center gap-1 text-xl text-sec-500 hover:text-pri-500"
            >
              <i class="ri-home-3-fill"></i> Home
            </Link>
            <div className="text-xl text-sec-500">/</div>
            <Link to="" className=" text-xl text-sec-500  hover:text-pri-500">
              LED Screens
            </Link>
            <div className="text-xl text-sec-500">/</div>
            <div className="text-xl text-sec-500 ">Paradise City Mall</div>
            <div className="text-xl text-sec-500">/</div>
            <div className="text-xl text-sec-500 font-semibold">
              Order Confirmation
            </div>
          </div>
          <div className="mt-20">
            <Steps current={step - 1} labelPlacement="vertical" items={items} />
          </div>
        </div>
      </div>
      <div className="py-5   px-3 ">
        <div className="container">
          {step === 1 && order}
          {step === 2 && checkout}
          {step === 3 && complete}
        </div>
      </div>
      <CalendarModal
        visible={openCalendar}
        handleClose={() => setOpenCalendar(false)}
      />
    </>
  );
}

export default PlaceOrder;
