import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AppLayout from "../layouts/AppLayout";
import SingleItemLayout from "../layouts/SingleItemLayout";
import WebLayout from "../layouts/WebLayout";
import AllItems from "../views/app/AllItems";
import LEDScreens from "../views/app/LEDScreens";
import PlaceOrder from "../views/app/PlaceOrder";
import SearchResult from "../views/app/SearchResult";
import SingleItemPage from "../views/app/SingleItemPage";
import Home from "../views/web/Home";

function Main() {
  return (
    <Routes>
      <Route element={<WebLayout />}>
        <Route path="home" element={<Home />} />
        <Route path="*" element={<Navigate replace to="/home" />} />
      </Route>

      <Route path="app/*" element={<AppLayout />}>
        <Route path="all-items" element={<AllItems />} />
        <Route path="led-screens" element={<LEDScreens />} />
        {/* <Route path="items" element={<SearchResult />} /> */}
        <Route path="*" element={<Navigate replace to="/app/all-items" />} />
      </Route>

      <Route path="single-item/*" element={<SingleItemLayout />}>
        <Route path="items" element={<SearchResult />} />
        <Route path="item" element={<SingleItemPage />} />
        <Route path="place-order" element={<PlaceOrder />} />
        <Route path="*" element={<Navigate replace to="/single-item/item" />} />
      </Route>
    </Routes>
  );
}

export default Main;
