import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="py-12 px-3 bg-sec-500">
        <div className="container">
          <div className="grid grid-cols-4 gap-5 text-base text-sec-200">
            <div>
              <div className="font-semibold">BRAND</div>
              <div className="text-sec-300 mb-2">
                Are you looking to use large format billboards to promote your
                business, service or event?
              </div>
              <Link className="text-sec-300 hover:text-sec-500 hover:bg-white  border-2 border-sec-300 hover:border-white font-semibold py-3 px-5 rounded-xl inline-block">
                <div className="flex items-center gap-2">
                  Request Call
                  <i className="ri-arrow-right-line"></i>
                </div>
              </Link>
            </div>
            <div>
              <div className="font-semibold">Company</div>
              <div>
                <Link className="text-sec-300 hover:text-pri-500" to="">
                  About
                </Link>
              </div>
              <div>
                <Link className="text-sec-300 hover:text-pri-500" to="">
                  Services
                </Link>
              </div>

              <div>
                <Link className="text-sec-300 hover:text-pri-500" to="">
                  Contact Us
                </Link>
              </div>
            </div>
            <div>
              <div>
                <div className="font-semibold">More</div>
                <Link className="text-sec-300 hover:text-pri-500" to="">
                  For Partners
                </Link>
              </div>
              <div>
                <Link className="text-sec-300 hover:text-pri-500" to="">
                  Gallery
                </Link>
              </div>
              <div>
                <Link className="text-sec-300 hover:text-pri-500" to="">
                  Privacy Policy
                </Link>
              </div>
              <div>
                <Link className="text-sec-300 hover:text-pri-500" to="">
                  Terms
                </Link>
              </div>
            </div>
            <div>
              <div className="font-semibold">Contact Us</div>
              <div className="flex items-start gap-2 text-sec-300">
                <i className="ri-map-pin-fill "></i>
                <div>75 Bremner Blvd, Toronto, Ontario, M5J 0A1</div>
              </div>
              <div className="flex items-start gap-2 text-sec-300">
                <i className="ri-mail-line"></i>
                <div>info@brand.com</div>
              </div>
              <div className="flex items-start gap-2 text-sec-300">
                <i className="ri-phone-line"></i>
                <div>(801) 692-3217</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-5 px-3 bg-sec-700 text-sec-300">
        <div className="container">
          <div className="flex justify-between items-center">
            <div>Copyright &copy; 2022 BRAND. All rights reserved</div>
            <div>
              Designed & Developed by{" "}
              <a
                className="text-sec-300 hover:text-pri-500"
                href="https://www.kodplex.com/home"
              >
                Kodplex (pvt) Ltd.
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
